
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'modalid',
      modaltitel: 'Titel',
      event: 'display-modal',
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(params) {
      this.$bvModal.show(this.modalid);
    },
  },
  mounted() {
    // this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    // this.$nuxt.$off(this.event, this.start);
  },

}
