
export default {
  mixins: [],
  props: [],
  data() {
    return {
      ziel: null,
      visible: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    speichern() {
      if (this.ziel.id) return this.update();
      this.$$store('ziele', this.ziel, 'Ziel erstellt').then((response) => {
        this.$emit('store-ziel', response);
        this.$emit('saved-ziel', response);
        this.reset();
      })
    },
    update() {
      this.$$update('ziele', this.ziel, 'Ziel aktualisiert').then((response) => {
        this.$emit('update-ziel', response);
        this.$emit('saved-ziel', response);
        this.reset();
      })
    },
    reset() {
      this.visible = false;
      this.ziel = {name: '', zieladresse: ''};
    }
  },
  mounted() {
    this.reset();
  },

}
